import React, { useState, useCallback } from "react";
import Gallery from "react-photo-gallery";
import Carousel, { Modal, ModalGateway } from "react-images";
import { penguins } from "../data/penguins";

function columns(containerWidth) {
    let columns = 2;
    if (containerWidth >= 500) columns = 3;
    if (containerWidth >= 900) columns = 4;
    if (containerWidth >= 1500) columns = 5;
    return columns;
}

export default function Penguins() {

    const [currentImage, setCurrentImage] = useState(0);
    const [viewerIsOpen, setViewerIsOpen] = useState(false);

    const openLightbox = useCallback((event, { photo, index }) => {
        setCurrentImage(index);
        setViewerIsOpen(true);
    }, []);

    const closeLightbox = () => {
        setCurrentImage(0);
        setViewerIsOpen(false);
    };

    const customStyles = {
        view: () => ({
            lineHeight: 0,
            position: 'relative',
            textAlign: 'center',
            boxSizing: 'border-box',
            height: window.innerWidth > 1000 ? window.innerHeight*0.833 : ''
        }),
    }

    return (
        <div>
            <Gallery columns={columns} direction={'column'} photos={penguins} onClick={openLightbox} />
            <ModalGateway>
                {viewerIsOpen ? (
                    <Modal onClose={closeLightbox}>
                        <Carousel
                            styles={customStyles}
                            currentIndex={currentImage}
                            views={penguins.map(x => ({
                                ...x,
                                srcset: x.srcSet,
                                caption: x.title
                            }))}
                        />
                    </Modal>
                ) : null}
            </ModalGateway>
        </div>
    );
}
