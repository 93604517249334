import React from "react";

export default function Footer() {
    return (
        <>
            <footer className="block py-4 bg-blue-50 pt-16 pb-4">

                <div className="container mx-auto px-4">

                    <div className="flex flex-wrap items-center md:justify-between justify-center">
                        <div className="w-full md:w-4/12 px-4">
                            <div className="text-sm text-gray-600 font-semibold py-1 text-center md:text-left">
                                Penguin Museum © {new Date().getFullYear()}
                            </div>
                        </div>

                    </div>
                </div>
            </footer>
        </>
    );
}
