export const penguins = [
    {
        src: "/penguins/north.jpg",
        width: 1,
        height: 1,
        title: '北極ペンギン'
    },
    {
        src: "/penguins/roulian.jpg",
        width: 1071,
        height: 1132,
        title: '顔こするペンギン'
    },
    {
        src: "/penguins/daidai.jpg",
        width: 740,
        height: 763,
        title: 'ぼんやりペンギン'
    },
    {
        src: "/penguins/wreath.jpeg",
        width: 3500,
        height: 2898
    },
    {
        src: "/penguins/look.jpg",
        width: 1065,
        height: 1118
    },
    {
        src: "/penguins/2022.jpeg",
        width: 1080,
        height: 1103
    },
    {
        src: "/penguins/bad.jpeg",
        width: 1019,
        height: 970
    },
    {
        src: "/penguins/wreath2.jpeg",
        width: 1466,
        height: 1080
    },
    {
        src: "/penguins/dog.jpg",
        width: 894,
        height: 932
    },
];
