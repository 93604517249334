import React from "react";
import {Link} from "react-router-dom";

import Navbar from "./components/Navbar.js";
import Footer from "./components/Footer.js";

export default class About extends React.Component {
    render() {
        return (
            <>
                <Navbar/>

                <div
                    className="font-sans max-w-screen-lg xl:max-w-screen-xl mx-auto space-y-32">

                    <section className="relative block h-500-px">
                        <div className="px-4 sm:px-6 md:px-8 mb-10 sm:mb-16 md:mb-20">
                            <h1 className="text-4xl sm:text-6xl lg:text-7xl leading-none font-extrabold tracking-tight mt-10 mb-8 sm:mt-14 sm:mb-10">
                                <span
                                    className="bg-clip-text text-transparent bg-gradient-to-r from-indigo-300 to-blue-400">
                                    Penguin Museum
                                </span>
                            </h1>
                            <p
                            className="max-w-4xl text-lg sm:text-2xl sm:leading-10 space-y-6 mb-6">
                                Welcome back, while we are continuing to work on collecting more penguins. Take a look inside!
                            </p>
                        </div>

                        <div
                            className="top-auto bottom-0 left-0 right-0 w-full absolute pointer-events-none overflow-hidden h-70-px"
                            style={{ transform: "translateZ(0)" }}
                        >
                            <svg className="absolute bottom-0 overflow-hidden" xmlns="http://www.w3.org/2000/svg"
                                 preserveAspectRatio="none" version="1.1" viewBox="0 0 2560 100" x="0" y="0">
                                <polygon className="text-gray-300 fill-current"
                                         points="2560 0 2560 100 0 100"></polygon>
                            </svg>
                        </div>
                    </section>

                    <div>
                        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                            <div className="lg:text-center">
                                <h2 className="text-base text-blue-500 font-semibold tracking-wide uppercase">All Known Species</h2>
                                <p className="mt-2 text-3xl leading-8 font-bold tracking-tight text-gray-600 sm:text-4xl">
                                    The Exhibition Hall
                                </p>
                                <p className="mt-4 max-w-2xl text-xl text-gray-500 lg:mx-auto">
                                    Explore the cute and queer penguins discovered and their stories.
                                </p>
                            </div>
                        </div>

                    </div>
                </div>

                <Footer/>

            </>
        );
    }
}
