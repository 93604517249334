import React from "react";

import Footer from "./components/Footer.js";
import Penguins from "./Penguins";

export default function Index() {

    return (
        <>
            <div
                className="font-sans max-w-screen-lg xl:max-w-screen-xl mx-auto space-y-32">

                <section className="relative block h-500-px">
                    <div className="px-4 sm:px-6 md:px-8 mb-10 sm:mb-16 md:mb-20">
                        <h1 className="text-4xl leading-none font-extrabold tracking-tight mt-10 mb-8 sm:mt-14 sm:mb-10">
                                <span
                                    className="bg-clip-text text-transparent bg-gradient-to-r from-indigo-300 to-blue-400">
                                    Penguin Gallery
                                </span>
                        </h1>

                        <div>
                            <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                                <div className="lg:text-center">
                                    <h2 className="text-base text-blue-500 font-semibold tracking-wide uppercase"></h2>
                                    <p className="mt-2 text-3xl leading-8 font-bold tracking-tight text-gray-600 sm:text-4xl">
                                        Exhibition Hall
                                    </p>
                                    <p className="mt-4 max-w-2xl text-xl text-gray-500 lg:mx-auto">
                                    </p>
                                </div>
                            </div>
                        </div>

                        <Penguins/>

                    </div>

                    <div
                        className="top-auto bottom-0 left-0 right-0 w-full absolute pointer-events-none overflow-hidden h-70-px"
                        style={{transform: "translateZ(0)"}}
                    >
                        <svg className="absolute bottom-0 overflow-hidden" xmlns="http://www.w3.org/2000/svg"
                             preserveAspectRatio="none" version="1.1" viewBox="0 0 2560 100" x="0" y="0">
                            <polygon className="text-gray-300 fill-current" points="2560 0 2560 100 0 100"/>
                        </svg>
                    </div>
                </section>

            </div>

            <Footer/>

        </>
    );
}
