import React from "react";
import { NavLink } from "react-router-dom";

export default function Navbar() {
    const DesktopNavItem = ({ text, ...rest }) => (
        <NavLink {...rest}
                 className="text-gray-900 hover:bg-blue-100 px-3 py-2 rounded-md text-sm font-medium focus:outline-blue"
                 activeClassName="bg-blue-200 hover:bg-blue-200">
            {text}
        </NavLink>
    )

    return (
        <>
            <nav className="">
                <div className="max-w-7xl mx-auto px-2 sm:px-6 lg:px-8">
                    <div className="relative flex items-center justify-between h-16">
                        <div className="absolute inset-y-0 left-0 flex items-center sm:hidden">
                            <button
                                className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-white hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                                aria-expanded="false">
                                <span className="sr-only">Open main menu</span>
                                <svg className="block h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none"
                                     viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"
                                          d="M4 6h16M4 12h16M4 18h16"/>
                                </svg>
                                <svg className="hidden h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none"
                                     viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"
                                          d="M6 18L18 6M6 6l12 12"/>
                                </svg>
                            </button>
                        </div>

                        <div className="flex-1 flex items-center justify-center sm:items-stretch sm:justify-start">
                            <div className="flex-shrink-0 flex items-center">
                                Penguin Gallery
                                {/*Penguin Logo*/}
                            </div>
                            <div className="hidden sm:block sm:ml-6">
                                <div className="flex space-x-4">
                                    <DesktopNavItem exact to="/" text="Home" />
                                    <DesktopNavItem exact to="/about" text="About Us" />
                                </div>
                            </div>
                        </div>
                        <div
                            className="absolute inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0">
                        </div>

                    </div>
                </div>

                <div className="hidden sm:hidden">
                    <div className="px-2 pt-2 pb-3 space-y-1">
                        <NavLink to="/"
                           className="bg-gray-900 text-white block px-3 py-2 rounded-md text-base font-medium">Home</NavLink>
                        <NavLink to="/about"
                           className="text-gray-300 hover:bg-gray-700 hover:text-white block px-3 py-2 rounded-md text-base font-medium">About Us</NavLink>

                    </div>
                </div>
            </nav>
        </>
    );
}
