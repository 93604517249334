import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";

import './assets/styles/index.css';
import Index from "./views/Index.js";
import About from "./views/About.js";

ReactDOM.render(
    <BrowserRouter>
        <Switch>
            <Route exact path="/">
                <Index />
            </Route>
            <Route exact path="/about">
                <About />
            </Route>

            <Redirect from="*" to="/"/>
        </Switch>
    </BrowserRouter>,
    document.getElementById("root")
);
